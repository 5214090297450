import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CMF_ADDRESS } from '@pa/lib-spa';
import { AssetPolicy, ClientPolicy, CmfResponse } from '@pa/sdk/cmf';

@Injectable({ providedIn: 'root' })
export class PolicyService {
    constructor(private _http: HttpClient, @Inject(CMF_ADDRESS) private _cmfAddress: string) {}

    getPolicy(policyId: string, behaviourId: string): Observable<ClientPolicy> {
        return this._http
            .get<CmfResponse<ClientPolicy>>(
                `${this._cmfAddress}/v1/clientPolicies/${policyId}?behaviour=${behaviourId}`
            )
            .pipe(map((resp) => resp.result));
    }

    getAssetPolicies(clientPolicyId: string, behaviourId: string): Observable<AssetPolicy[]> {
        return this._http
            .get<CmfResponse<AssetPolicy[]>>(
                `${this._cmfAddress}/v1/assetPolicies?clientPolicy=${clientPolicyId}&behaviour=${behaviourId}`
            )
            .pipe(map((res) => res.result));
    }

    getPolicyHistory(policyId: string, behaviourId: string): Observable<any> {
        return this._http
            .get<any>(`${this._cmfAddress}/v1/clientPolicies/${policyId}/history?behaviour=${behaviourId}`)
            .pipe(map((resp) => resp.result));
    }

    getESPolicies(config: {
        createdDateFrom?: string;
        createdDateTo?: string;
        searchTerm?: string;
        behaviourId?: string;
        behaviours: string;
    }): Observable<any> {
        let query = '';
        Object.keys(config).forEach((key, index) => {
            if (index === 0) {
                query += `?${key}=${config[key]}`;
            } else {
                query += `&${key}=${config[key]}`;
            }
        });

        return this._http
            .get<any>(`${this._cmfAddress}/v1/elasticsearch/policy${query}`)
            .pipe(map((res) => res.result));
    }

    getESInsurerAssetTemplates(behaviour: string, categoryCode: string): Observable<any> {
        return this._http
            .get<any>(`${this._cmfAddress}/v1/insurerAssetTemplate?behaviour=${behaviour}&categoryCode=${categoryCode}`)
            .pipe(map((res) => res.result));
    }
}
