import _ from 'lodash';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
    AmendType,
    CoverageType,
    coverageTypes,
    CurrencyCode,
    DiscountType,
    EU_REG_LOCALES,
    formatCurrencyAmountByFormatter,
    getCurrencyFormatter,
    Insurer,
    PriceType,
    TransactionType,
} from '@pa/references/idf';
import { assetTypes, AssetType } from '@pa/references/paul-precision';
import {
    AgGridEvent,
    GridOptions,
    ValueFormatterParams,
    ValueGetterParams,
    ValueParserParams,
    ValueSetterParams,
} from 'ag-grid-community';
import { AssetModel, PremiumEntry } from '../../types';
import { getRowStyle } from '../utils';
import { isPinnedBottom } from '../../../../../../src/app/underwriting/utils/ag-grid';

@Component({
    selector: 'asset-premiums',
    templateUrl: './premium.component.html',
})
export class PremiumComponent implements OnInit {
    @Input() asset: AssetModel;
    @Output() validated = new EventEmitter<boolean>();

    readonly EDITABLE_HEADER_TOOLTIP: string = 'Click to edit the value';
    readonly MIN_LIABILITY_LIMIT: number = 250000;
    discountRate: number = 0;

    currencyFormatter: Intl.NumberFormat = getCurrencyFormatter(CurrencyCode.AUD);

    premiumsGridOptions: GridOptions = {
        defaultColDef: {
            resizable: true,
        },
    };

    constructor() {}

    ngOnInit(): void {
        this.currencyFormatter = getCurrencyFormatter(this.asset.currency);
        if (this.asset.discountPriceYear?.length) {
            this.discountRate = this.asset.discountPriceYear
                .filter((dpy) => dpy.type === DiscountType.policyRemainingProRata)
                .reduce((acc, cur) => acc + (cur.rate ?? 0), 0);
        }

        this.bindPremiumsGridOptions();
        this.validate();
    }

    private bindPremiumsGridOptions() {
        const asset = this.asset;
        const discountRate = this.discountRate;

        let prices = {
            hull: asset.priceYearArray?.find((pya) => pya.type === PriceType.hull)?.rate,
            csl: asset.priceYearArray?.find((pya) => pya.type === PriceType.csl)?.liability,
            tpl: asset.priceYearArray?.find((pya) => pya.type === PriceType.tpl)?.liability,
            pll: asset.priceYearArray?.find((pya) => pya.type === PriceType.pll)?.liability,
        };

        Object.keys(prices).forEach((priceType) => {
            const price = prices[priceType];
            if (price !== undefined && price !== null) {
                const precision = priceType === PriceType.hull ? 4 : 2;
                prices[priceType] = _.round(price, precision);
            }
        });

        const calculatePremium = (type: PriceType, val: number = 0): number => {
            val = val ?? 0;
            if (type === PriceType.hull) {
                return _.round((asset.initialValue ?? 0) * val, 2);
            } else {
                return _.round(val, 2);
            }
        };

        const calculateProRata = (type: PriceType, val: number | undefined, oldPremium?: number): number => {
            const newPremium = calculatePremium(type, val ?? 0);
            let proRata = newPremium - (oldPremium ?? 0);

            return _.round(proRata * (1 - discountRate), 2);
        };

        const hullRow: PremiumEntry = {
            type: PriceType.hull,
            label: 'Hull',
            newLimit: asset.agreedValue,
            newValue: prices.hull,
            newPremium: (asset?.agreedValue ?? 0) * (prices.hull ?? 0),
        };
        const cslRow: PremiumEntry = {
            type: PriceType.csl,
            label: 'CSL',
            newLiabilityLimit: asset.liabilityLimitArray?.find((l) => l.type === PriceType.csl),
            newLimit: asset.liabilityLimits?.csl,
            newValue: prices.csl,
            newPremium: prices.csl,
        };
        const tplRow: PremiumEntry = {
            type: PriceType.tpl,
            label: 'TPL',
            newLiabilityLimit: asset.liabilityLimitArray?.find((l) => l.type === PriceType.tpl),
            newLimit: asset.liabilityLimits?.thirdParty,
            newValue: prices.tpl,
            newPremium: prices.tpl,
        };
        const pllRow: PremiumEntry = {
            type: PriceType.pll,
            label: 'PLL',
            newLiabilityLimit: asset.liabilityLimitArray?.find((l) => l.type === PriceType.pll),
            newLimit: asset.liabilityLimits?.passenger,
            newValue: prices.pll,
            newPremium: prices.pll,
        };

        if (asset.amendType === AmendType.removed) {
            const priceChanged = (type: string) => (asset.pricesChanged || {})[type];
            [hullRow, cslRow, tplRow, pllRow].forEach((row) => {
                row = Object.assign(row, {
                    newLimit: 0,
                });
                if (!priceChanged(row.type)) row.newPremium = row.newValue = 0;
            });
            Object.keys(prices).forEach((priceType) => {
                if (!priceChanged(priceType)) prices[priceType] = 0;
            });
        }

        const isAmendment = [TransactionType.amendment, TransactionType.cancellation].includes(asset.transactionType);
        const isRenewal = asset.transactionType === TransactionType.renewal;

        let oldHullPremium = 0;
        if ([TransactionType.newBusiness, TransactionType.renewal].includes(asset.transactionType)) {
            hullRow.proRataPremium = calculateProRata(PriceType.hull, prices.hull);
            cslRow.proRataPremium = calculateProRata(PriceType.csl, prices.csl);
            tplRow.proRataPremium = calculateProRata(PriceType.tpl, prices.tpl);
            pllRow.proRataPremium = calculateProRata(PriceType.pll, prices.pll);
        }
        if (asset.transactionType !== TransactionType.newBusiness) {
            hullRow.oldLimit = asset.assetPolicy?.agreedValue;
            const oldPriceYear = asset.assetPolicy?.priceYearArray?.find((pya) => pya.type === PriceType.hull);
            hullRow.oldPremium = oldPriceYear?.rate;
            if (hullRow.oldPremium !== undefined && hullRow.oldPremium !== null) {
                oldHullPremium = _.round(oldPriceYear?.hull ?? 0, 2);
                hullRow.oldPremium = _.round(hullRow.oldPremium, 4);
            }
            hullRow.proRataPremium = calculateProRata(PriceType.hull, prices.hull, isRenewal ? 0 : oldPriceYear?.hull);

            cslRow.oldLimit = asset.assetPolicy?.liabilityLimits?.csl;
            cslRow.oldLiabilityLimit = asset.assetPolicy?.liabilityLimitArray?.find((l) => l.type === PriceType.csl);
            cslRow.oldPremium = asset.assetPolicy?.priceYearArray?.find((pya) => pya.type === PriceType.csl)?.liability;
            if (cslRow.oldPremium !== undefined && cslRow.oldPremium !== null) {
                cslRow.oldPremium = _.round(cslRow.oldPremium, 2);
            }
            cslRow.proRataPremium = calculateProRata(PriceType.csl, prices.csl, isRenewal ? 0 : cslRow.oldPremium);

            tplRow.oldLimit = asset.assetPolicy?.liabilityLimits?.thirdParty;
            tplRow.oldLiabilityLimit = asset.assetPolicy?.liabilityLimitArray?.find((l) => l.type === PriceType.tpl);
            tplRow.oldPremium = asset.assetPolicy?.priceYearArray?.find((pya) => pya.type === PriceType.tpl)?.liability;
            if (tplRow.oldPremium !== undefined && tplRow.oldPremium !== null) {
                tplRow.oldPremium = _.round(tplRow.oldPremium, 2);
            }
            tplRow.proRataPremium = calculateProRata(PriceType.tpl, prices.tpl, isRenewal ? 0 : tplRow.oldPremium);

            pllRow.oldLimit = asset.assetPolicy?.liabilityLimits?.passenger;
            pllRow.oldLiabilityLimit = asset.assetPolicy?.liabilityLimitArray?.find((l) => l.type === PriceType.pll);
            pllRow.oldPremium = asset.assetPolicy?.priceYearArray?.find((pya) => pya.type === PriceType.pll)?.liability;
            if (pllRow.oldPremium !== undefined && pllRow.oldPremium !== null) {
                pllRow.oldPremium = _.round(pllRow.oldPremium, 2);
            }
            pllRow.proRataPremium = calculateProRata(PriceType.pll, prices.pll, isRenewal ? 0 : pllRow.oldPremium);
        }

        asset.premiums = asset.coverageType === CoverageType.ffrLiab ? [] : [hullRow];
        if (asset.liabilityLimitArray) {
            [cslRow, tplRow, pllRow].forEach((premiumRow) => {
                if (asset.liabilityLimitArray.some((liab) => liab.type === premiumRow.type)) {
                    asset.premiums.push(premiumRow);
                }
            });
        } else {
            // Fallback to deprecated liability property
            const { liabilityLimits } = asset;
            if (liabilityLimits?.csl) asset.premiums.push(cslRow);
            if (liabilityLimits?.thirdParty) asset.premiums.push(tplRow);
            if (liabilityLimits?.passenger) asset.premiums.push(pllRow);
        }

        this.premiumsGridOptions.columnDefs = [
            {
                headerName: 'Type',
                field: 'label',
            },
            {
                headerName: 'New Limit',
                field: 'newLimit',
                valueFormatter: (params: ValueFormatterParams<PremiumEntry>) => {
                    const liabilityLimit = params.data.newLiabilityLimit;

                    return this.currencyValueFormatter(
                        params,
                        liabilityLimit ? getCurrencyFormatter(liabilityLimit.currency) : this.currencyFormatter
                    );
                },
            },
            {
                headerName: 'New Premium',
                field: 'newValue',
                editable: (params) => !isPinnedBottom(params),
                headerClass: 'editable-highlight',
                headerTooltip: this.EDITABLE_HEADER_TOOLTIP,
                valueGetter: (params: ValueGetterParams): number | void => {
                    // Use this valueGetter to transform rate value to percentage
                    if (params.data.type === PriceType.hull) {
                        if (params.data.newValue !== undefined && params.data.newValue !== null) {
                            return _.round(params.data.newValue * 100, 2);
                        }
                    }

                    return params.data.newValue;
                },
                valueFormatter: (params: ValueFormatterParams): string => {
                    if (params.value === undefined || params.value === null || params.value === '') {
                        return '';
                    }
                    if (params.data.type === PriceType.hull) {
                        return `${_.round(parseFloat(params.value), 2)}%`;
                    }

                    return this.currencyValueFormatter(params);
                },
                valueParser: (params: ValueParserParams): number => {
                    const { newValue } = params;
                    let result: number;
                    if (newValue === undefined || newValue === '') {
                        result = params.oldValue;
                    } else {
                        result = _.toNumber(newValue);
                    }

                    if (asset.transactionType !== TransactionType.cancellation) {
                        if (params.data.type === PriceType.hull) {
                            if (typeof result === 'number') {
                                result = _.round(result / 100, 4);
                            }
                        }
                    }

                    return result;
                },
                valueSetter: (params: ValueSetterParams<PremiumEntry>) => {
                    if (!this.asset.pricesChanged) {
                        this.asset.pricesChanged = {};
                    }
                    this.asset.pricesChanged[params.data.type] = true;

                    let oldPremium = 0;
                    if (asset.transactionType !== TransactionType.cancellation && !isRenewal) {
                        if (params.data.type === PriceType.hull) {
                            oldPremium = oldHullPremium;
                        } else {
                            oldPremium = params.data.oldPremium;
                        }
                    }

                    params.data.newValue = params.newValue;
                    params.data.newPremium = calculatePremium(params.data.type, params.newValue);
                    params.data.proRataPremium = calculateProRata(params.data.type, params.newValue, oldPremium);
                    return true;
                },
                cellClass: (params) => 'edit-' + params.data.type + '-premium',
            },
            {
                headerName: 'Pro-rata Premium',
                field: 'proRataPremium',
                valueFormatter: this.currencyValueFormatter,
                cellClass: (params) => params.data.type + '-premium',
            },
        ];

        const amendmentShowOldPremiumCondition = isAmendment && (asset.amendType ?? AmendType.new) !== AmendType.new;

        if (amendmentShowOldPremiumCondition || asset.transactionType === TransactionType.renewal) {
            this.premiumsGridOptions.columnDefs.splice(
                1,
                0,
                {
                    headerName: 'Old Limit',
                    field: 'oldLimit',
                    valueFormatter: (params: ValueFormatterParams<PremiumEntry>) => {
                        const liabilityLimit = params.data.oldLiabilityLimit;

                        return this.currencyValueFormatter(
                            params,
                            liabilityLimit ? getCurrencyFormatter(liabilityLimit.currency) : this.currencyFormatter
                        );
                    },
                },
                {
                    headerName: 'Old Premium',
                    field: 'oldPremium',
                    valueGetter: (params: ValueGetterParams): number | void => {
                        if (params.data.type === PriceType.hull) {
                            if (params.data.oldPremium !== undefined && params.data.oldPremium !== null) {
                                return _.round(params.data.oldPremium * 100, 2);
                            }
                            return 0;
                        }

                        return _.round(params.data.oldPremium ?? 0, 2);
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        if (params.value === undefined || params.value === null || isPinnedBottom(params)) {
                            return '';
                        }
                        if (params.data.type === PriceType.hull) {
                            return `${_.round(parseFloat(params.value), 2)}%`;
                        }

                        return this.currencyValueFormatter(params);
                    },
                }
            );
        }

        this.premiumsGridOptions.singleClickEdit = true;
        this.premiumsGridOptions.pinnedBottomRowData = this._totalPremiumRow();
    }

    currencyValueFormatter = (
        params: ValueFormatterParams<PremiumEntry>,
        currencyFormatter = this.currencyFormatter
    ): string =>
        params.value === undefined || params.value === null
            ? ''
            : formatCurrencyAmountByFormatter(params.value, currencyFormatter);

    rowDataChanged(params: AgGridEvent, gridOptions: GridOptions, triggerValidation = false) {
        // if (this.to.autoWidth === 'content') {
        //     // Fit content width
        //     const columnApi = params.columnApi;
        //     const allColumnIds = columnApi.getColumns().map((c: any) => c.getColId());
        //     columnApi.autoSizeColumns(allColumnIds);
        // } else {
        //     // Fit window width
        //     params.api.sizeColumnsToFit();
        // }
        this.resize(params, gridOptions);

        params.api.setPinnedBottomRowData(this._totalPremiumRow());

        if (triggerValidation) {
            this.validate();
        }
    }

    private _totalPremiumRow() {
        const totalProRata = _.sum(this.asset.premiums.map((p) => p.proRataPremium));
        return totalProRata ? [{ label: 'Total Premium', proRataPremium: totalProRata }] : [];
    }

    resize(params: AgGridEvent, gridOptions: GridOptions) {
        if (params?.columnApi.getColumns()) {
            const columnApi = params.columnApi;
            const allColumnIds = columnApi.getColumns().map((c: any) => c.getColId());
            columnApi.autoSizeColumns(allColumnIds);
        }
        gridOptions?.api?.sizeColumnsToFit();
    }

    validate(): void {
        const { insurer, premiums, coverageType, assetType, agreedValue, liabilityLimits, liabilityLimitArray } =
            this.asset;

        const liabPrices: PriceType[] = [];
        if (liabilityLimitArray?.length) {
            liabilityLimitArray.forEach((liab) => {
                if (([PriceType.tpl, PriceType.pll, PriceType.csl] as PriceType[]).includes(liab.type)) {
                    liabPrices.push(liab.type);
                }
            });
        } else {
            if (insurer === Insurer.hssb) {
                liabPrices.push(PriceType.tpl, PriceType.pll);
            } else if (insurer === Insurer.hdi) {
                liabPrices.push(PriceType.csl);
            } else if (insurer === Insurer.hscb) {
                liabPrices.push(PriceType.csl);
            } else {
                liabPrices.push(PriceType.tpl);
            }
        }

        const priceTypes: PriceType[] = [];
        switch (assetType) {
            case assetTypes.aircraft:
                switch (coverageType) {
                    case coverageTypes.fullflightRisk:
                    case coverageTypes.groundHullFfrLiab:
                    default:
                        priceTypes.push(PriceType.hull, ...liabPrices);
                        break;
                    case coverageTypes.extendedGround:
                    case coverageTypes.groundOnly:
                        priceTypes.push(PriceType.hull);
                        break;
                    case coverageTypes.ffrLiab:
                        priceTypes.push(...liabPrices);
                        break;
                }
                break;
            case assetTypes.uav:
                // FIXME: Use UAV coverage types (PP-1566)
                if (liabilityLimits?.thirdParty) {
                    priceTypes.push(PriceType.tpl);
                }
                if (agreedValue) {
                    priceTypes.push(PriceType.hull);
                }
                break;
            case assetTypes.uavPayload:
            case assetTypes.uavEquipment:
                priceTypes.push(PriceType.hull);
        }

        const isValid = priceTypes.every((type) => {
            const premium = premiums?.find((p) => p.type === type);
            const newValue = premium?.newValue;
            const hasValue = typeof newValue === 'number';

            if (type === PriceType.hull) {
                const withinRange = newValue >= -1 && newValue <= 1;

                return hasValue && withinRange;
            }
            return hasValue;
        });

        this.validated.emit(isValid);
    }

    getRowStyle(params) {
        return getRowStyle(params);
    }
}
