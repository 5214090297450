import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Auth, Hub } from 'aws-amplify';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ThemeService } from '@pa/lib-spa';

import { NavItem } from '../../const/const';
import { Theme } from '@pa/sdk/idf';
import { AuthService } from '../auth/auth.service';
import { FeatureFlags } from '@pa/references/idf';

@Component({
    selector: 'app-nav',
    templateUrl: 'navigation.component.html',
    styleUrls: ['navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
    @ViewChild('snav') sideNav: MatSidenav;
    @ViewChild('snavContent') sideNavContent: MatSidenavContent;
    menu: NavItem[] = [];
    logo: SafeResourceUrl;

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        private spinner: NgxSpinnerService,
        private themeService: ThemeService,
        private sanitizer: DomSanitizer,
        private authService: AuthService
    ) {
        Hub.listen('auth', async (capsule: any) => {
            const { channel, payload } = capsule; // source
            if (channel === 'auth' && payload.event === 'signIn') {
                await this.initNavItems();
            }
        });
    }

    async ngOnInit() {
        await this.initNavItems();
        this.authService.featureFlags.subscribe({
            next: (flags) => {
                flags?.forEach((flag) => {
                    switch (flag.type) {
                        case FeatureFlags.insurerCustomerServicing: {
                            this._addMenuItem('Customer Servicing', 'quotes-policies');
                            break;
                        }
                        case FeatureFlags.insurerDistributionManagement: {
                            this._addMenuItem('Distribution Management', 'distribution-management');
                            break;
                        }
                        case FeatureFlags.insurerRateManagementView: {
                            this._addMenuItem('Rate Management', 'rate-management');
                            break;
                        }
                        case FeatureFlags.insurerReferralManagement: {
                            this._addMenuItem('Referrals', 'referrals');
                            break;
                        }
                        case FeatureFlags.insurerReporting: {
                            this._addMenuItem('Reporting', '');
                            break;
                        }
                        case FeatureFlags.insurerRateVersioningView: {
                            this._addMenuItem('Stored Underwriting Configurations', 'view-underwriting');
                            break;
                        }

                        default:
                            break;
                    }
                });
            },
        });

        // workaround for sidenav overlay issue occasionally
        if (this.sideNav.opened) {
            this.sideNavContent.getElementRef().nativeElement.style.marginLeft = '150px';
        }

        this.themeService.theme.subscribe((theme) => {
            this.logo = theme.logo
                ? this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml;base64,${theme.logo}`)
                : '';
            this.updateFavicon(theme);
        });
    }

    async initNavItems() {
        this.spinner.show();
        try {
            const user = await Auth.currentUserInfo();

            //TODO show/hide item based on cognito user roles
            this.menu = [
                {
                    displayName: 'Dashboard',
                    iconName: 'desktop_windows',
                    route: '',
                },
                {
                    displayName: 'Claims',
                    iconName: 'desktop_windows',
                    route: 'claims',
                },
                {
                    displayName: 'Underwriting',
                    iconName: 'desktop_windows',
                    route: 'underwriting',
                },
            ] as NavItem[];
        } catch (e) {
            console.log('cognito error');
        }
        this.spinner.hide();
    }

    public updateFavicon(theme: Theme) {
        const href = theme.favicon ? `data:image/svg+xml;base64,${theme.favicon}` : '';
        const linkElement = document.createElement('link');
        linkElement.setAttribute('class', 'favicon');
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', 'image/svg+xml');
        linkElement.setAttribute('href', href);
        document.head.appendChild(linkElement);
        if (document.head.getElementsByClassName('favicon').length > 1) {
            document.head.getElementsByClassName('favicon')[0].remove();
        }
    }

    public signOut() {
        Auth.signOut();
    }

    private _addMenuItem(display_name: string, route: string) {
        const index = this.menu.findIndex((m) => m.displayName === display_name);
        index === -1
            ? this.menu.push({
                  displayName: display_name,
                  iconName: 'desktop_windows',
                  route: route,
              })
            : null;
        return;
    }
}
