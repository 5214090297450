<div id="title-bar">
    <span class="col-md-2">
        <a data-testid="back-to-search-results" routerLink="/distribution-management"> << Back to search results</a>
    </span>
    <span class="col-md-8">View Organisation</span>
    <span class="col-md-2" hidden></span>
</div>
<div id="organisation-details">
    <mat-card  appearance="outlined"  class="col-md-6">
        <table class="mat-table">
            <tr class="mat-row">
                <th class="mat-header-cell">Organisation Name</th>
                <td class="mat-cell">Organisation Name will be shown here</td>
            </tr>
            <tr class="mat-row">
                <th class="mat-header-cell">Landing URL</th>
                <td class="mat-cell">Organisation Landing URL will be shown here</td>
            </tr>
            <tr class="mat-row">
                <th class="mat-header-cell">Default Originator</th>
                <td class="mat-cell">Organisation default Originator will be shown here</td>
            </tr>
        </table>
    </mat-card>
    <mat-card  appearance="outlined"  class="col-md-6">
        <table class="mat-table">
            <tr class="mat-row">
                <th class="mat-header-cell">Behaviour</th>
                <td class="mat-cell">Selected behaviour will be shown here</td>
            </tr>
            <tr class="mat-row">
                <th class="mat-header-cell">Commission</th>
                <td class="mat-cell">Commission from the selected behaviour will be shown here</td>
            </tr>
            <tr class="mat-row">
                <th class="mat-header-cell">Commission Tax</th>
                <td class="mat-cell">Commission Tax from the selected behaviour will be shown here</td>
            </tr>
        </table>
    </mat-card>
</div>
<div id="originators-detail">
    <form [formGroup]="form" class="col-md-10">
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    </form>
</div>
