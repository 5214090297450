// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    serviceName: 'CoverPortal',
    env: 'prestage',
    amplify: {
        Auth: {
            mandatorySignIn: true,
            region: 'us-west-2',
            userPoolId: 'us-west-2_tyFAAbA0z',
            identityPoolId: '',
            userPoolWebClientId: '2p8ur4hrm9pj1pod2odgbgsj30',
        },
    },
    apiBaseUrls: {
        cmf: 'https://cmf.pre-pastage.studio/api',
        rmf: 'https://rmf.pre-pastage.studio/api',
        idf: 'https://idf.pre-pastage.studio/api',
        pay: 'https://pay.pre-pastage.studio/api',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
