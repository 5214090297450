import _ from 'lodash';
import moment from 'moment-timezone';
import { combineLatest } from 'rxjs';
import { Auth } from 'aws-amplify';
import { ChangeDetectorRef, Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig, FormlyForm } from '@ngx-formly/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BehaviourService, ThemeService, mapAttachments } from '@pa/lib-spa';
import { TransactionType, UnderwritingActions } from '@pa/references/idf';
import {
    ClientProposal,
    ClientQuote,
    DocumentationLink,
    UnderwritingDecision,
    UnderwritingDecisionNew,
} from '@pa/sdk/idf';

import { AuthService } from '../core/auth/auth.service';
import { QuoteService } from '../services/quote.service';
import { mapClientAddress } from '../utils/address';
import { formatDate, mapEffectiveDateLabel, mapProposalEffectiveDate } from '../utils/date';
import { ConfirmDialogComponent } from '../components/dialogs/confirm-dialog.component';
import { SdkService } from '../services/sdk.service';
import { NotificationService } from '../common/notification.service';
import { Attachment } from '@pa/lib-spa/types';

interface ClientQuoteComponentModel {
    clientProposalId?: string;
    behaviourId?: string;
    transactionType?: TransactionType;
    quoteStatus?: string;
    companyName?: string;
    companyAddress?: string;
    effectiveDateLabel?: string;
    effectiveDate?: string;
    holdCoverExpiryDate?: string;
    holdCoverReasons: string[];
    hasHoldCoverDecisionMade?: boolean;
    holdCoverDecision?: UnderwritingDecision;
    timezone?: string;
    attachments: Attachment[];
}

interface AcceptFormModel {
    validExpiryDate?: moment.Moment;
}

interface DeclineFormModel {
    reasons?: string;
}

@Component({
    selector: 'client-quote',
    templateUrl: './client-quote.component.html',
    styleUrls: ['client-quote.component.scss'],
})
export class ClientQuoteComponent implements OnInit {
    @ViewChild('acceptDialog', { static: false }) acceptDialog: TemplateRef<any> | undefined;
    @ViewChild('declineDialog', { static: false }) declineDialog: TemplateRef<any> | undefined;
    @ViewChild('confirmDialog') confirmDialog: ConfirmDialogComponent;
    @ViewChild('acceptForm') acceptForm: FormlyForm;
    @ViewChild('declineForm') declineForm: FormlyForm;

    dialogRef: MatDialogRef<any> | undefined;

    proposal?: ClientProposal;
    model: ClientQuoteComponentModel = {
        effectiveDateLabel: 'Proposed Inception Date',
        holdCoverReasons: [],
        attachments: [],
    };
    loading = true;
    submitting = false;
    action?: UnderwritingActions;
    theme: string;

    acceptFormModel: AcceptFormModel = {};
    acceptFormFields: FormlyFieldConfig[] = [
        {
            type: 'datepicker',
            key: 'validExpiryDate',
            templateOptions: {
                attributes: {
                    'data-testid': 'valid-expiry-date',
                },
                placeholder: 'What date would you like the hold cover request to expire?',
                required: true,
                wrappers: ['form-field'],
                datepickerOptions: {
                    datepickerTogglePosition: 'prefix',
                },
            },
        },
    ];

    declineFormModel: DeclineFormModel = {};
    declineFormFields: FormlyFieldConfig[] = [
        {
            key: 'reasons',
            type: 'textarea',
            templateOptions: {
                label: 'Decline Reasons',
                rows: 3,
                maxLength: 5000,
                attributes: {
                    'data-testid': 'decline-reasons-textbox',
                },
            },
            wrappers: ['form-field'],
        },
    ];

    constructor(
        public dialog: MatDialog,
        private router: ActivatedRoute,
        private behaviourService: BehaviourService,
        private quoteService: QuoteService,
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private themeService: ThemeService,
        private notificationService: NotificationService,
        private sdk: SdkService
    ) { }

    ngOnInit() {
        combineLatest([this.router.queryParams, this.authService.userSignedInSubject]).subscribe(
            async ([queryParams, hasSignedIn]) => {
                const { proposalId, behaviourId } = queryParams;

                if (!proposalId || !behaviourId || !hasSignedIn) {
                    return;
                }

                this.model = Object.assign({}, this.model, {
                    clientProposalId: proposalId,
                    behaviourId,
                });

                await Promise.all([this.getBehaviour(), this.getQuote()]);
                this.loadAttachments();
                this.loading = false;
                this.changeDetectorRef.detectChanges();
            }
        );
        this.themeService.theme.subscribe((theme) => {
            this.theme = theme.slug;
        });
    }

    openAcceptDialog() {
        if (this.acceptDialog) {
            this.ngZone.run(() => {
            this.dialogRef = this.dialog.open(this.acceptDialog, {
                panelClass: this.theme,
            });
        });
        }
    }

    openDeclineDialog() {
        if (this.declineDialog) {
            this.ngZone.run(() => {
            this.dialogRef = this.dialog.open(this.declineDialog, {
                panelClass: this.theme,
            });
            });

        }
    }

    openConfirmDialog(action: UnderwritingActions) {
        this.closeDecisionDialog();
        this.action = action;
        if (action === UnderwritingActions.accept) {
            this.confirmDialog.title = 'Confirm Accepting';
        } else {
            this.confirmDialog.title = 'Confirm Declination';
        }

        this.confirmDialog.confirmBtnLabel = _.capitalize(action);
        this.confirmDialog.open(`Please confirm you want to ${action} this request to hold coverage.`);
    }

    closeDecisionDialog() {
        this.ngZone.run(() => this.dialogRef?.close());
    }

    private getBehaviour = async () => {
        const behaviour = await this.quoteService.getBehaviour(this.model.behaviourId).toPromise();

        this.model = Object.assign({}, this.model, { behaviourId: behaviour._id });
        this.behaviourService.set(behaviour);
    };

    private getQuote = async () => {
        const { clientProposalId, behaviourId } = this.model;

        const proposal = await this.quoteService.getProposal(clientProposalId, behaviourId).toPromise();
        this.proposal = proposal;

        this.model = Object.assign({}, this.model, {
            transactionType: proposal.transactionType,
            quoteStatus: proposal.clientQuote?.status ? _.capitalize(proposal.clientQuote?.status) : '',
            companyName: proposal.company.companyName,
            companyAddress: mapClientAddress(proposal.company, true),
            effectiveDateLabel: mapEffectiveDateLabel(proposal.transactionType),
            effectiveDate: formatDate(mapProposalEffectiveDate(proposal), proposal.timezone),
            holdCoverExpiryDate: formatDate(proposal.holdCoverExpiryDate, proposal.timezone),
            holdCoverReasons: proposal.holdCoverReasons ?? [],
            hasHoldCoverDecisionMade: !!proposal.clientQuote?.holdCoverDecision?.action,
            holdCoverDecision: proposal.clientQuote?.holdCoverDecision,
        });

        const validExpiryDateFormField = this.acceptFormFields.find((f) => f.key === 'validExpiryDate');
        if (validExpiryDateFormField) {
            validExpiryDateFormField.templateOptions.datepickerOptions.min = moment();
        }

        const { holdCoverExpiryDate, timezone } = proposal;
        if (holdCoverExpiryDate) {
            this.acceptFormModel.validExpiryDate = moment.utc(holdCoverExpiryDate).tz(timezone);
        }
    };

    private loadAttachments = () => {
        this.behaviourService.behaviour.subscribe((behaviour) => {
            const { transactionType, documentationLinks } = this.proposal;

            if (documentationLinks) {
                this.model.attachments = mapAttachments(
                    this.proposal.documentationLinks?.map(
                        (dl) =>
                        ({
                            fileName: dl.filename,
                            url: dl.url,
                        } as Attachment)
                    ) ?? [],
                    behaviour,
                    transactionType
                );
            }
        });
    };

    async decisionAction() {
        this.submitting = true;
        const { behaviourId, clientProposalId } = this.model;
        const { action, proposal } = this;

        try {
            const auth = await Auth.currentSession();
            this.sdk.idf.accessToken = auth.getAccessToken().getJwtToken();

            const decisionPaylod: UnderwritingDecisionNew = {
                action,
            };
            if (action === UnderwritingActions.accept) {
                decisionPaylod.validExpiryDate = this.acceptFormModel.validExpiryDate
                    ?.tz(proposal.timezone)
                    .startOf('day')
                    .toISOString();
            } else {
                if (this.declineFormModel.reasons) {
                    decisionPaylod.reasons = [this.declineFormModel.reasons];
                }
            }

            const res = await this.sdk.idf.ClientQuotes.submitHodeCoverDecision(
                behaviourId,
                clientProposalId,
                decisionPaylod
            );

            this.notificationService.success('Hold cover request has been accepted!');
            const holdCoverDecision = (res as ClientQuote).holdCoverDecision;
            this.model = Object.assign({}, this.model, {
                hasHoldCoverDecisionMade: !!holdCoverDecision,
                holdCoverDecision,
            });
            this.closeDecisionDialog();
        } catch (err) {
            console.error(err);
            this.notificationService.error(
                'Accepting hold cover request errorred! Please try again later or contact us.'
            );
        } finally {
            this.submitting = false;
        }
    }
}
