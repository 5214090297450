import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
    AmendType,
    coverageTypes,
    CurrencyCode,
    DiscountType,
    EndorsementCategory,
    EndorsementPriceInclusion,
    formatCurrencyAmountByFormatter,
    getCurrencyFormatter,
    PriceType,
    TransactionType,
} from '@pa/references/idf';
import { QuotePriceInput } from '@pa/sdk/idf';
import { AssetPolicy, Endorsement, PolicyPrice } from '@pa/sdk/cmf';
import {
    AgGridEvent,
    GridOptions,
    ValueFormatterParams,
    ValueGetterParams,
    ValueSetterParams,
} from 'ag-grid-community';
import _ from 'lodash';
import { isPinnedBottom } from '../../../../../../src/app/underwriting/utils/ag-grid';
import {
    getEndorsementPrice,
    saveEndorsementPriceYear,
} from '../../../../../../src/app/underwriting/utils/endorsements';

import { AssetModel, UnderwritingComponentEndorsement } from '../../types';

import { adjustPrices, applyDiscount, displayRateVersion, getRowStyle } from '../utils';

@Component({
    selector: 'asset-endorsements',
    templateUrl: './endorsements.component.html',
})
export class EndorsementsComponent implements OnInit {
    @Input() asset: AssetModel;
    @Output() validated = new EventEmitter<boolean>();

    readonly EDITABLE_HEADER_TOOLTIP: string = 'Click to edit the value';

    public currencyFormatter: Intl.NumberFormat = getCurrencyFormatter(CurrencyCode.AUD);
    public discountRate: number = 0;
    public endorsementsGridOptions: GridOptions = {
        defaultColDef: {
            resizable: true,
        },
        overlayNoRowsTemplate: '<span>No proposed endorsements.</span>',
    };
    public existingEndorsementsGridOptions: GridOptions = {
        defaultColDef: {
            resizable: true,
        },
        overlayNoRowsTemplate: '<span>No existing endorsements.</span>',
    };
    public existingEndorsements: AssetModel['endorsements'];
    public isValid: boolean;
    public showExistingPolicy = false;

    constructor() {}

    ngOnInit() {
        this.currencyFormatter = getCurrencyFormatter(this.asset.currency);
        this.showExistingPolicy = !!this.asset.assetPolicy;

        const asset = this.asset;
        const assetPolicy = this.asset.assetPolicy;

        if (assetPolicy) {
            this.existingEndorsements = this._filterEndorsements(this.asset.existingEndorsements);

            this.existingEndorsementsGridOptions = this._bindExistingEndorsementsGridOptions(
                this.existingEndorsementsGridOptions,
                assetPolicy
            );
        }

        if (asset.discountPriceYear?.length) {
            this.discountRate = _.round(
                asset.discountPriceYear
                    .filter((dpy) => dpy.type === DiscountType.policyRemainingProRata)
                    .reduce((acc, cur) => acc + (cur.rate ?? 0), 0),
                4
            );
        }

        asset.endorsements = this._filterEndorsements(asset.endorsements);

        this.bindEndorsementsGridOptions(asset.transactionType);

        if (!asset.priceYearArray) {
            asset.priceYearArray = [];
        }
        if (!asset.priceYearAdjusted) {
            this.asset.priceYearAdjusted = [];
        }

        this.validate();
    }

    private _bindExistingEndorsementsGridOptions(gridOptions: GridOptions, assetPolicy: AssetPolicy) {
        gridOptions.columnDefs = [
            {
                headerName: 'Endorsement',
                field: 'title',
            },
            {
                headerName: 'Price',
                valueFormatter: this.currencyValueFormatter,
                valueGetter: (params: ValueGetterParams) =>
                    getEndorsementPrice(params.data, undefined, undefined, params),
            },
            {
                headerName: 'Premium',
                valueFormatter: this.currencyValueFormatter,
                valueGetter: (params: ValueGetterParams): number | void => {
                    const type = params.data.type;
                    const priceYear = assetPolicy.priceYearArray?.find((pya) => pya.type === type);
                    let premium = priceYear?.liability ?? priceYear?.standard;

                    if (typeof premium === 'number') {
                        return _.round(premium, 2);
                    }
                },
            },
        ];

        if (this.existingEndorsements.some((e) => e?.underwritingConfigVersions?.length)) {
            gridOptions.columnDefs.splice(1, 0, {
                headerName: 'Rate Version',
                valueGetter: (params: ValueGetterParams) => {
                    return displayRateVersion(params.data.underwritingConfigVersions);
                },
            });
        }

        return gridOptions;
    }

    private _filterEndorsements(endorsements: Endorsement[]) {
        const includeEndorsements: readonly EndorsementCategory[] = [
            EndorsementCategory.inferred,
            EndorsementCategory.optional,
            EndorsementCategory.uses,
        ];

        return endorsements.filter((e) => includeEndorsements.includes(e.category));
    }

    private _getPrice(type: PriceType, pricesArray: PolicyPrice[]) {
        const existingPrice = pricesArray.find((pya) => pya.type === type);
        const premium = existingPrice?.liability ?? existingPrice?.standard ?? 0;

        return _.round(premium, 2);
    }

    private bindEndorsementsGridOptions(transactionType: TransactionType) {
        const asset = this.asset;
        this.endorsementsGridOptions.singleClickEdit = true;

        this.endorsementsGridOptions.columnDefs = [
            {
                headerName: 'Endorsement',
                field: 'title',
            },
            {
                headerName: 'Sublimit',
                field: 'limit',
                valueFormatter: (params: ValueFormatterParams<UnderwritingComponentEndorsement>) => {
                    const limitCurrency = params.data.limitCurrency;
                    return this.currencyValueFormatter(
                        params,
                        limitCurrency ? getCurrencyFormatter(limitCurrency) : this.currencyFormatter
                    );
                },
                valueGetter: (params: ValueGetterParams) => params.data.limit,
            },
            {
                headerName: 'Price',
                valueFormatter: this.currencyValueFormatter,
                valueGetter: (params: ValueGetterParams) =>
                    getEndorsementPrice(params.data, params.data.liabilityLimits?.premises, null, params),
            },
            {
                headerName: 'Premium',
                editable: (params) => !isPinnedBottom(params),
                headerClass: 'editable-highlight',
                headerTooltip: this.EDITABLE_HEADER_TOOLTIP,
                valueFormatter: this.currencyValueFormatter,
                valueGetter: (params: ValueGetterParams): number | void => {
                    const type = params.data.type;
                    const priceYear = asset.priceYearArray?.find((pya) => pya.type === type);
                    let premium = priceYear?.liability ?? priceYear?.standard;

                    if (premium !== undefined && premium !== null) {
                        if (this.asset.amendType === AmendType.removed) {
                            premium =
                                this._getPrice(params.data.type, asset.assetPolicy?.priceYearArray ?? []) - premium;
                        }

                        return _.round(premium, 2);
                    }
                },
                valueSetter: (params: ValueSetterParams): boolean => {
                    let premium = params.newValue;
                    if (premium === '' || isNaN(premium) || !_.isNumber(_.toNumber(premium))) {
                        return false;
                    }
                    if (asset.amendType === AmendType.removed) {
                        premium = this._getPrice(params.data.type, asset.assetPolicy?.priceYearArray ?? []) - premium;
                    }

                    premium = _.round(premium, 2);

                    const type = params.data.type;

                    let priceYear: QuotePriceInput | undefined = asset.priceYearArray?.find((pya) => pya.type === type);
                    if (!priceYear) {
                        priceYear = { type };
                        saveEndorsementPriceYear(priceYear, premium);
                        asset.priceYearArray?.push(priceYear);
                    } else {
                        saveEndorsementPriceYear(priceYear, premium);
                    }

                    let priceYearAdjusted: QuotePriceInput | undefined = asset.priceYearAdjusted?.find(
                        (pya) => pya.type === type
                    );
                    if (!priceYearAdjusted) {
                        priceYearAdjusted = { type };
                        saveEndorsementPriceYear(priceYearAdjusted, premium);
                        asset.priceYearAdjusted?.push(priceYearAdjusted);
                    }

                    const isAmendment = [TransactionType.amendment, TransactionType.cancellation].includes(
                        transactionType
                    );
                    const existingPrice = isAmendment
                        ? asset.assetPolicy?.priceYearArray.find((pya) => pya.type === type)
                        : undefined;
                    Object.assign(
                        priceYearAdjusted,
                        adjustPrices(priceYear, existingPrice ?? { type }, asset.agreedValue ?? 0, asset.amendType)
                    );

                    params.api.setRowData(asset.endorsements);
                    this.validate();
                    return true;
                },
                cellClass: (params) => 'edit-' + params.data.type + '-premium',
            },
            {
                headerName: 'Pro-rata Premium',
                valueFormatter: this.currencyValueFormatter,
                valueGetter: (params: ValueGetterParams): number | void => {
                    const priceYear = asset.priceYearAdjusted?.find((pya) => pya.type === params.data.type);
                    let premium = priceYear?.liability ?? priceYear?.standard ?? 0;

                    if (isPinnedBottom(params)) {
                        premium = this.totalPremium();
                    }

                    return applyDiscount(premium, this.discountRate);
                },
                cellClass: (params) => params.data.type + '-premium',
            },
        ];

        if (asset.endorsements.some((e) => e?.underwritingConfigVersions?.length)) {
            this.endorsementsGridOptions.columnDefs.splice(1, 0, {
                headerName: 'Rate Version',
                valueGetter: (params: ValueGetterParams) => {
                    return displayRateVersion(params.data.underwritingConfigVersions);
                },
            });
        }
    }

    currencyValueFormatter = (
        params: ValueFormatterParams<UnderwritingComponentEndorsement>,
        currencyFormatter = this.currencyFormatter
    ): string => {
        this.validate();

        return params.value === undefined || params.value === null
            ? ''
            : formatCurrencyAmountByFormatter(params.value, currencyFormatter);
    };

    rowDataChanged(params: AgGridEvent, gridOptions: GridOptions, triggerValidation = false) {
        // if (this.to.autoWidth === 'content') {
        //     // Fit content width
        //     const columnApi = params.columnApi;
        //     const allColumnIds = columnApi.getColumns().map((c: any) => c.getColId());
        //     columnApi.autoSizeColumns(allColumnIds);
        // } else {
        //     // Fit window width
        //     params.api.sizeColumnsToFit();
        // }
        this.resize(params, gridOptions);

        if (triggerValidation) {
            this.validate();
        }

        params.api.setPinnedBottomRowData(this.totalPremium() ? [{ title: 'Total Premium' }] : []);
    }

    totalPremium() {
        return _.sum(
            this.asset.endorsements.map((e) => {
                const priceYear = this.asset.priceYearAdjusted?.find((pya) => pya.type === e.type);
                const premium = priceYear?.liability ?? priceYear?.standard ?? 0;
                return premium;
            })
        );
    }

    resize(params: AgGridEvent, gridOptions: GridOptions) {
        if (params?.columnApi.getColumns()) {
            const columnApi = params.columnApi;
            const allColumnIds = columnApi.getColumns().map((c: any) => c.getColId());
            columnApi.autoSizeColumns(allColumnIds);
        }
        gridOptions?.api?.sizeColumnsToFit();
    }

    getRowStyle(params: any) {
        return getRowStyle(params);
    }

    validate() {
        const { endorsements, priceYearAdjusted, priceYearArray, coverageType } = this.asset;
        const isValid = endorsements
            ?.filter((e) => getEndorsementPrice(e, this.asset.liabilityLimits?.premises) !== 0)
            .every((e) => {
                const hasPremium =
                    priceYearAdjusted?.map((pya) => pya.type).includes(e.type) ||
                    priceYearArray?.map((pya) => pya.type).includes(e.type);

                if (hasPremium) {
                    return hasPremium;
                }

                switch (e.inclusive) {
                    case EndorsementPriceInclusion.hull:
                        return (
                            coverageType === coverageTypes.fullflightRisk ||
                            coverageType === coverageTypes.groundHullFfrLiab ||
                            coverageType === coverageTypes.groundOnly ||
                            coverageType === coverageTypes.extendedGround
                        );
                    case EndorsementPriceInclusion.liability:
                        return (
                            coverageType === coverageTypes.fullflightRisk ||
                            coverageType === coverageTypes.groundHullFfrLiab ||
                            coverageType === coverageTypes.ffrLiab
                        );
                    default:
                        return false;
                }
            });

        this.isValid = isValid;
        this.validated.emit(isValid);
    }
}
