import { Injectable } from '@angular/core';

import { SdkEnv } from '@pa/sdk';
import { Cmf } from '@pa/sdk/cmf';
import { Idf } from '@pa/sdk/idf';
import { Rmf } from '@pa/sdk/rmf';

import { environment } from '../../../src/environments/environment';

@Injectable({
    providedIn: 'platform',
})
export class SdkService {
    private readonly _cmfService: Cmf;
    private readonly _idfService: Idf;
    private readonly _rmfService: Rmf;

    constructor() {
        this._cmfService = new Cmf({
            serviceName: environment.serviceName,
            env: environment.env as SdkEnv,
        });
        this._idfService = new Idf({
            serviceName: environment.serviceName,
            env: environment.env as SdkEnv,
        });
        this._rmfService = new Rmf({
            serviceName: environment.serviceName,
            env: environment.env as SdkEnv,
        });
    }

    get cmf() {
        return this._cmfService;
    }

    get idf() {
        return this._idfService;
    }

    get rmf() {
        return this._rmfService;
    }
}
