<mat-card appearance="outlined" class="mb-3">
    <mat-card-title>Basic Info</mat-card-title>
    <mat-card-content>
        <div class="row">
            <div class="col-md-6" *ngIf="showExistingPolicy">
                <h3>
                    <strong>Existing {{ assetType }}</strong>
                </h3>
                <table class="mat-table">
                    <tr *ngIf="policyInfo.model" class="mat-row">
                        <th class="mat-header-cell">Model:</th>
                        <td class="mat-cell">{{ policyInfo.model }}</td>
                    </tr>
                    <tr *ngIf="policyInfo.rateVersion" class="mat-row">
                        <th class="mat-header-cell" data-testid="existingAssetRateVersion">Rate Version:</th>
                        <td class="mat-cell">
                    <tr *ngFor="let version of policyInfo.rateVersion">
                        {{
                        version
                        }}
                    </tr>
                    </td>
                    </tr>
                    <tr *ngIf="policyInfo.registration" class="mat-row">
                        <th class="mat-header-cell">Registration:</th>
                        <td class="mat-cell">{{ policyInfo.registration }}</td>
                    </tr>
                    <tr *ngIf="policyInfo.serialNumber" class="mat-row">
                        <th class="mat-header-cell">Aircraft Serial Number:</th>
                        <td class="mat-cell">{{ policyInfo.serialNumber }}</td>
                    </tr>
                    <tr *ngIf="policyInfo.location" class="mat-row">
                        <th class="mat-header-cell">Based In:</th>
                        <td class="mat-cell">
                            {{ policyInfo.location }}
                        </td>
                    </tr>
                    <tr *ngIf="policyInfo.coverageType" class="mat-row">
                        <th class="mat-header-cell">Coverage Type:</th>
                        <td class="mat-cell">
                            {{ policyInfo.coverageType }}
                        </td>
                    </tr>
                    <tr *ngIf="policyInfo.agreedValue" class="mat-row">
                        <th class="mat-header-cell" data-testid="policyInfoAgreedValue">
                            {{ getLabel('agreedValue', 'Agreed Value') }}:
                        </th>
                        <td class="mat-cell">
                            {{ policyInfo.agreedValue }}
                        </td>
                    </tr>
                    <tr *ngIf="assetType === 'aircraft' && !!policyInfo.opw" class="mat-row">
                        <th class="mat-header-cell">OPW:</th>
                        <td class="mat-cell">
                            {{ policyInfo.opw }}
                        </td>
                    </tr>
                    <tr *ngIf="policyInfo.uses" class="mat-row">
                        <th class="mat-header-cell">Uses:</th>
                        <td class="mat-cell">
                            <ol *ngIf="policyInfo.uses?.length">
                                <li *ngFor="let use of policyInfo.uses">{{ use }}</li>
                            </ol>
                        </td>
                    </tr>
                    <tr *ngIf="policyInfo.operations" class="mat-row">
                        <th class="mat-header-cell">Operations:</th>
                        <td class="mat-cell">
                            <ol *ngIf="policyInfo.operations?.length">
                                <li *ngFor="let operation of policyInfo.operations">{{ operation }}</li>
                            </ol>
                        </td>
                    </tr>
                    <tr *ngIf="policyInfo.passengerSeats >= 0" class="mat-row">
                        <th class="mat-header-cell">Passenger Seats:</th>
                        <td class="mat-cell">
                            {{ policyInfo.passengerSeats }}
                        </td>
                    </tr>
                    <tr *ngIf="policyInfo.crewSeats >= 0" class="mat-row">
                        <th class="mat-header-cell">Crew Seats:</th>
                        <td class="mat-cell">
                            {{ policyInfo.crewSeats }}
                        </td>
                    </tr>
                    <tr *ngIf="policyInfo.storageType" class="mat-row">
                        <th class="mat-header-cell">Storage Type:</th>
                        <td class="mat-cell">
                            {{ policyInfo.storageType }}
                        </td>
                    </tr>
                </table>
            </div>
            <div [ngClass]="infoTableClass()">
                <h3 *ngIf="showExistingPolicy">
                    <strong>Proposed {{ assetType }} ({{ info.amendType }})</strong>
                </h3>
                <table class="mat-table">
                    <tr *ngIf="info.model" class="mat-row">
                        <th class="mat-header-cell">Model:</th>
                        <td class="mat-cell">{{ info.model }}</td>
                    </tr>
                    <tr *ngIf="info.rateVersion" class="mat-row">
                        <th class="mat-header-cell" data-testid="assetRateVersion">Rate Version:</th>
                        <td class="mat-cell">
                    <tr *ngFor="let version of info.rateVersion">
                        {{
                        version
                        }}
                    </tr>
                    </td>
                    </tr>
                    <tr *ngIf="info.registration" class="mat-row">
                        <th class="mat-header-cell">Registration:</th>
                        <td class="mat-cell">{{ info.registration }}</td>
                    </tr>
                    <tr *ngIf="info.serialNumber" class="mat-row">
                        <th class="mat-header-cell">Aircraft Serial Number:</th>
                        <td class="mat-cell">{{ info.serialNumber }}</td>
                    </tr>
                    <tr *ngIf="info.location" class="mat-row">
                        <th class="mat-header-cell">Based In:</th>
                        <td class="mat-cell">
                            {{ info.location }}
                        </td>
                    </tr>
                    <tr *ngIf="info.coverageType" class="mat-row">
                        <th class="mat-header-cell">Coverage Type:</th>
                        <td class="mat-cell">
                            {{ info.coverageType }}
                        </td>
                    </tr>
                    <tr *ngIf="info.agreedValue" class="mat-row">
                        <th class="mat-header-cell" data-testid="infoAgreedValue">
                            {{ getLabel('agreedValue', 'Agreed Value') }}:
                        </th>
                        <td class="mat-cell">
                            {{ info.agreedValue }}
                        </td>
                    </tr>
                    <tr *ngIf="assetType === 'aircraft' && !!info.opw" class="mat-row">
                        <th class="mat-header-cell">OPW:</th>
                        <td class="mat-cell">
                            {{ info.opw }}
                        </td>
                    </tr>
                    <tr *ngIf="info.uses" class="mat-row">
                        <th class="mat-header-cell">Uses:</th>
                        <td class="mat-cell">
                            <ol *ngIf="info.uses?.length">
                                <li *ngFor="let use of info.uses">{{ use }}</li>
                            </ol>
                        </td>
                    </tr>
                    <tr *ngIf="info.operations" class="mat-row">
                        <th class="mat-header-cell">Operations:</th>
                        <td class="mat-cell">
                            <ol *ngIf="info.operations?.length">
                                <li *ngFor="let operation of info.operations">{{ operation }}</li>
                            </ol>
                        </td>
                    </tr>
                    <tr *ngIf="info.passengerSeats >= 0" class="mat-row">
                        <th class="mat-header-cell">Passenger Seats:</th>
                        <td class="mat-cell">
                            {{ info.passengerSeats }}
                        </td>
                    </tr>
                    <tr *ngIf="info.crewSeats >= 0" class="mat-row">
                        <th class="mat-header-cell">Crew Seats:</th>
                        <td class="mat-cell">
                            {{ info.crewSeats }}
                        </td>
                    </tr>
                    <tr *ngIf="info.storageType" class="mat-row">
                        <th class="mat-header-cell">Storage Type:</th>
                        <td class="mat-cell">
                            {{ info.storageType }}
                        </td>
                    </tr>
                    <tr *ngIf="info.mtom" class="mat-row">
                        <th class="mat-header-cell" data-testid="mtom">MTOM:</th>
                        <td class="mat-cell">
                            {{ info.mtom }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </mat-card-content>
</mat-card>