<div>
    <h1>Underwriting</h1>
    <mat-card  appearance="outlined"  class="mb-3">
        <mat-card-header>
            <mat-card-title>Quote Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table class="mat-table w-100">
                <tr class="mat-row">
                    <th class="mat-header-cell w-25">Transaction Type:</th>
                    <td class="mat-cell">
                        {{ model.transactionType }}
                    </td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Quote Status:</th>
                    <td class="mat-cell">
                        {{ model.quoteStatus }}
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>

    <mat-card  appearance="outlined"  class="mb-3">
        <mat-card-header>
            <mat-card-title>Policy Wide Details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table class="mat-table w-100">
                <tr class="mat-row">
                    <th class="mat-header-cell w-25">Insured Name:</th>
                    <td class="mat-cell">
                        {{ model.companyName }}
                    </td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Address:</th>
                    <td class="mat-cell">
                        {{ model.companyAddress }}
                    </td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">{{ model.effectiveDateLabel }}:</th>
                    <td class="mat-cell">
                        {{ model.effectiveDate }}
                    </td>
                </tr>
                <tr data-testid="hold-cover-expiry-date" class="mat-row" *ngIf="model.holdCoverExpiryDate">
                    <th class="mat-header-cell">Proposed Hold Cover Expiry:</th>
                    <td class="mat-cell">
                        {{ model.holdCoverExpiryDate }}
                    </td>
                </tr>
                <tr data-testid="hold-cover-expiry-date" class="mat-row" *ngIf="model.holdCoverExpiryDate">
                    <th class="mat-header-cell">Proposed Hold Cover Reasons:</th>
                    <td class="mat-cell">
                        <ul>
                            <li *ngFor="let reason of model.holdCoverReasons">
                                {{ reason }}
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>

    <mat-card  appearance="outlined"  class="mb-3">
        <mat-card-header>
            <mat-card-title>Documents</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table class="table table-sm table-borderless" style="min-width: 500px">
                <tr *ngFor="let attachment of model.attachments">
                    <td style="width: 30px">
                        <i class="fal fa-file-alt fa-lg mr-1"></i>
                    </td>
                    <td>
                        <a [href]="attachment.url" target="_blank"> {{ attachment.fileName }}</a>
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>

    <div *ngIf="!loading && !model.hasHoldCoverDecisionMade">
        <div class="row pb-5">
            <div class="col-md-12 text-right">
                <button
                    [disabled]="submitting"
                    mat-raised-button
                    color="Warn"
                    (click)="openDeclineDialog()"
                    data-testid="open-decline-modal"
                >
                    <i *ngIf="submitting && action === 'decline'" class="fal fa-spinner-third fa-spin"></i>
                    Decline
                </button>
                <button
                    [disabled]="submitting"
                    mat-raised-button

                    type="button"
                    class="ml-2 btn btn-primary"
                    (click)="openAcceptDialog()"
                    data-testid="open-accept-modal"
                >
                    <i *ngIf="submitting && action === 'accept'" class="fal fa-spinner-third fa-spin"></i>
                    Accept
                </button>
            </div>
        </div>
    </div>

    <ng-template #acceptDialog>
        <mat-dialog-content [style.minWidth.px]="500">
            <div data-testid="accept-dialog">
                <h1>Accept</h1>
                <div>
                    <formly-form #acceptForm [model]="acceptFormModel" [fields]="acceptFormFields"></formly-form>
                </div>
                <div class="text-center mt-4 mb-1">
                    <button
                        mat-raised-button
                        type="button"
                        (click)="closeDecisionDialog()"
                        data-testid="accept-dialog-close-button"
                    >
                        Close
                    </button>
                    <button
                        mat-raised-button
                        class="ml-2 btn btn-primary"
                        type="button"
                        cdkFocusInitial
                        [disabled]="acceptForm.form.invalid"
                        (click)="openConfirmDialog('accept')"
                        data-testid="accept-dialog-accept-button"
                    >
                        Accept
                    </button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>
    <ng-template #declineDialog>
        <mat-dialog-content [style.minWidth.px]="500">
            <div data-testid="decline-dialog">
                <h1>Decline</h1>
                <div>
                    <formly-form #declineForm [model]="declineFormModel" [fields]="declineFormFields"></formly-form>
                </div>
                <div class="text-center mt-4 mb-1">
                    <button
                        mat-raised-button
                        type="button"
                        (click)="closeDecisionDialog()"
                        data-testid="decline-dialog-close-button"
                    >
                        Close
                    </button>
                    <button
                        mat-raised-button
                        class="ml-2 btn btn-primary"
                        class="ml-2"
                        type="button"
                        cdkFocusInitial
                        [disabled]="declineForm.form.invalid"
                        (click)="openConfirmDialog('decline')"
                        data-testid="decline-dialog-decline-button"
                    >
                        Decline
                    </button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <confirm-dialog #confirmDialog (confirmClicked)="decisionAction()"></confirm-dialog>
</div>
