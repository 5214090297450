<div>
    <asset-basic-info [asset]="asset"></asset-basic-info>
    <asset-premiums [asset]="asset" (validated)="onPremiumsValidated($event)"></asset-premiums>
    <asset-endorsements [asset]="asset" (validated)="onEndorsementsValidated($event)"></asset-endorsements>
    <mat-card  appearance="outlined"  class="mb-3">
        <mat-card-title>Interested Parties</mat-card-title>
        <mat-card-content>
            <ag-grid-angular
                domLayout="autoHeight"
                class="ag-theme-material"
                [gridOptions]="interestedPartiesGridOptions"
                [rowData]="asset.interestedParties"
                (rowDataChanged)="rowDataChanged($event, interestedPartiesGridOptions)"
                (gridSizeChanged)="resize($event, interestedPartiesGridOptions)"
                style="width: 100%"
            ></ag-grid-angular>
        </mat-card-content>
    </mat-card>
    <mat-card  appearance="outlined"  class="mb-3" *ngIf="showExistingPolicy">
        <mat-card-title>Existing Pilots</mat-card-title>
        <mat-card-content>
            <ag-grid-angular
                domLayout="autoHeight"
                class="ag-theme-material"
                [gridOptions]="existingPilotsGridOptions"
                [rowData]="existingPilots"
                (rowDataChanged)="rowDataChanged($event, existingPilotsGridOptions)"
                (gridSizeChanged)="resize($event, existingPilotsGridOptions)"
                style="width: 100%"
            ></ag-grid-angular>
        </mat-card-content>
    </mat-card>
    <mat-card  appearance="outlined"  class="mb-3">
        <mat-card-title>Proposed Pilots</mat-card-title>
        <mat-card-content>
            <ag-grid-angular
                domLayout="autoHeight"
                class="ag-theme-material"
                [gridOptions]="pilotsGridOptions"
                [rowData]="asset.pilots"
                (rowDataChanged)="rowDataChanged($event, pilotsGridOptions)"
                (gridSizeChanged)="resize($event, pilotsGridOptions)"
                style="width: 100%"
            ></ag-grid-angular>
        </mat-card-content>
    </mat-card>
</div>
