import { ColumnApi, GridApi, GridOptions, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviourService } from '@pa/lib-spa';
import { originatorTypes } from '@pa/references/idf';
import { Behaviour } from '@pa/sdk/idf';

import { PolicyService } from '../services/policy.service';
import { QuoteService } from '../services/quote.service';
import { formatDate } from '../utils/temporal';

interface colDefs {
    id: string;
    policyNumber: string;
    insuredName: string;
    broker: string;
    revision: string;
    transactionType: string;
    effectiveDate: string;
}

@Component({
    selector: 'app-quote-policy-home-page',
    templateUrl: './quote-policy-home-page.component.html',
    styleUrls: ['./quote-policy-home-page.component.scss'],
})
export class QuotePolicyHomePageComponent implements OnInit {
    public behaviours: Behaviour[] = [];
    behaviourSelected: Behaviour | undefined;
    dataLoading: boolean = true;
    behavioursLoading = true;
    gridApi: GridApi;
    gridColumnApi: ColumnApi;
    paginationPageSize: number = 50;
    rowData: colDefs[] = [];
    searchDisabled: boolean = true;
    searchQuery: string = '';
    showBehaviourSelector: boolean = true;
    showTable: boolean = false;
    search$: Subject<string> = new Subject<string>();

    gridOptions: GridOptions = {
        columnDefs: [
            {
                field: 'id',
                hide: true,
            },
            {
                headerName: 'Policy Number',
                field: 'policyNumber',
                flex: 1,
            },
            {
                headerName: 'Insured Name',
                field: 'insuredName',
                flex: 1,
            },
            {
                headerName: 'Broker',
                field: 'broker',
                flex: 1,
            },
            {
                headerName: 'Revision',
                field: 'revision',
                flex: 1,
            },
            {
                headerName: 'Transaction Type',
                field: 'transactionType',
                flex: 1,
            },
            {
                headerName: 'Effective Date',
                field: 'effectiveDate',
                flex: 1,
            },
        ],
        rowHeight: 48,
        suppressRowHoverHighlight: false,
        overlayNoRowsTemplate: '<span>Data not available</span>',
        singleClickEdit: true,
        rowStyle: { cursor: 'pointer' },
        animateRows: true,
        onRowClicked: (event: RowClickedEvent) => {
            let url: string;
            if (event.data.policyNumber) {
                url = `/clientPolicy?policyId=${event.data.id}&behaviourId=${this.behaviourSelected._id}`;
            } else {
                url = `/viewQuote?proposalId=${event.data.id}&behaviourId=${this.behaviourSelected._id}`;
            }
            this._updateSessionDetails();
            this.router.navigateByUrl(url);
        },
    };

    constructor(
        private policyService: PolicyService,
        private quoteService: QuoteService,
        private router: Router,
        private behaviourService: BehaviourService
    ) {
        this.search$
            .pipe(
                debounceTime(1000),
                switchMap((searchText) => {
                    this.dataLoading = true;
                    this.searchQuery = searchText;
                    this._updateSessionDetails();
                    if (searchText) {
                        this.rowData = [];
                    }

                    const today = moment.utc().toISOString();
                    const fromDate = moment(today).clone().subtract(30, 'days').toISOString();
                    const query = { behaviourId: this.behaviourSelected._id, behaviours: this._getBehaviourGroup() };

                    if (searchText) {
                        query['searchTerm'] = searchText;
                    } else {
                        query['createdDateFrom'] = fromDate;
                        query['createdDateTo'] = today;
                    }

                    return forkJoin([
                        this.quoteService.getESQuotes(query).pipe(catchError((err) => of([]))),
                        this.policyService.getESPolicies(query).pipe(catchError((err) => of([]))),
                    ]);
                })
            )
            .subscribe({
                next: ([quotes, policies]) => {
                    this.rowData.push(...this._mapESData([...(quotes ?? []), ...(policies ?? [])]));
                    this.gridApi?.setRowData(this.sortRowDataByEffectiveDate());
                    this.dataLoading = false;
                },
                error: (err) => {
                    this.dataLoading = false;
                },
            });
    }

    ngOnInit(): void {
        this.showTable = false;
        if (sessionStorage.getItem('quotePolicyData')) {
            const data = JSON.parse(sessionStorage.getItem('quotePolicyData'));
            if (data.behaviour) {
                this.updateBehaviour(data.behaviour);
                this.searchByBehaviour();
            }
            if (typeof data.query === 'string') {
                this.searchQuery = data.query === '' ? undefined : data.query;
                this.search();
            }
        }

        this.quoteService.getBehavioursfromCognito().subscribe((behaviours) => {
            this.behaviours = behaviours;
            this.behavioursLoading = false;
        });
    }

    search(): void {
        this.search$.next(this.searchQuery);
    }

    updateBehaviour(behaviour: Behaviour) {
        this.behaviourSelected = behaviour;
        this.behaviourService.set(this.behaviourSelected);
    }

    searchByBehaviour() {
        this.rowData = [];
        this.showTable = true;
        this.showBehaviourSelector = false;

        this._updateSessionDetails();

        const today = moment.utc().toISOString();
        const fromDate = moment(today).clone().subtract(30, 'days').toISOString();

        this.dataLoading = true;

        const query = {
            createdDateFrom: fromDate,
            createdDateTo: today,
            behaviourId: this.behaviourSelected._id,
            behaviours: this._getBehaviourGroup(),
        };
        forkJoin([
            this.quoteService.getESQuotes({ quoteStatus: 'none', ...query }).pipe(catchError((err) => of([]))),
            this.policyService.getESPolicies(query).pipe(catchError((err) => of([]))),
        ]).subscribe({
            next: ([quotes, policies]) => {
                this.rowData.push(...this._mapESData([...(quotes ?? []), ...(policies ?? [])]));
                this.gridApi?.setRowData(this.sortRowDataByEffectiveDate());
                this.searchDisabled = false;
                this.dataLoading = false;
            },
            error: (err) => {
                this.dataLoading = false;
            },
        });
    }

    onGridReady(params): void {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    private _mapESData(data: any[]): any[] {
        return data.map((d) => {
            let effectiveDate: string = '';
            if (d.reference) {
                effectiveDate = this._getEffectiveDate([
                    d.inceptionDate,
                    d.amendedDate,
                    d.renewalDate,
                    d.cancellationDate,
                ]);
            } else {
                effectiveDate = this._getEffectiveDate([
                    d.inceptionDate,
                    d.amendmentEffectiveDate,
                    d.renewalEffectiveDate,
                    d.cancellationEffectiveDate,
                ]);
            }

            let row: colDefs = {
                id: d._id,
                policyNumber: d.reference ? d.reference : '',
                broker: d.originator.type === originatorTypes.intermediary ? d.originator.individual : 'Direct',
                revision: this.getRevisionInfo(d),
                transactionType: d.reference ? '' : d.transactionType,
                effectiveDate: formatDate(
                    moment.utc(effectiveDate).toISOString(),
                    d.clientProposal?.timezone ?? d.timezone
                ),

                insuredName: d.company.companyName,
            };
            return row;
        });
    }

    private sortRowDataByEffectiveDate() {
        return this.rowData.sort((a, b) => {
            const element1 = moment(a.effectiveDate);
            const element2 = moment(b.effectiveDate);

            if (element1.isSame(element2)) return 0;
            if (element1.isBefore(element2)) return 1;
            return -1;
        });
    }

    convertTZ(date, tzString) {
        return new Date(
            (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString })
        );
    }

    private getRevisionInfo(data) {
        if (data.reference) {
            if (data.referenceRevision) {
                return `Revision ${data.referenceRevision}`;
            } else {
                return ``;
            }
        } else if (data.revision) {
            return ` Revision ${data.revision}`;
        } else if (data.referenceRevisionRevision) {
            return `Revision ${data.referenceRevision || 0}${
                data.referenceRevisionRevision ? `(${data.referenceRevisionRevision})` : ''
            }`;
        } else {
            return '';
        }
    }

    private _updateSessionDetails() {
        sessionStorage.setItem(
            'quotePolicyData',
            JSON.stringify({ behaviour: this.behaviourSelected, query: this.searchQuery })
        );
    }

    private _getEffectiveDate(dates: string[]) {
        return dates.reduce((d1, d2) => {
            if (!d1 && !d2) {
                return;
            } else if (!d1) {
                return d2;
            } else if (!d2) {
                return d1;
            }
            return d1 > d2 ? d1 : d2;
        });
    }

    private _getBehaviourGroup() {
        return [
            this.behaviourSelected.insurer,
            this.behaviourSelected.product,
            this.behaviourSelected.locale,
            this.behaviourSelected.market,
        ].join('_');
    }
}
